import { IImageData } from "../interfaces/IImageData";

export const ROOM_IMAGES: IImageData[] = [
	{
		src: "../../images/b-313/20181029LocalPolyOut-0002.jpg"
	},
	{
		src: "../../images/b-313/20181029LocalPolyOut-0003.jpg"
	},
	{
		src: "../../images/b-313/20181029LocalPolyOut-0004.jpg"
	},
	{
		src: "../../images/b-313/20181029LocalPolyOut-0005.jpg"
	}
];
