import React from "react";
import Container from "../../components/Container";
import css from "./Calendar.module.css";

function Calendar() {
	return (
		<Container withAnimation={true}>
			<React.Fragment>
				<h2>Calendrier</h2>
				<p className={css.center}>
					Rendez-vous sur nos pages Facebook et Instagram pour ne rien manquer!
				</p>
				<p className={css.emptyPage}></p>
			</React.Fragment>
		</Container>
	);
}

export default Calendar;
