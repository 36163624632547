import React from "react";
import { Link } from "react-router-dom";
import { INavbarItem } from "../../models/interfaces/INavbarItem";

function NavbarItemsMobile({
	items,
	onClickCallback
}: {
	items: INavbarItem[];
	onClickCallback: () => void;
}) {
	return (
		<React.Fragment>
			{items.map((item: INavbarItem, index: number) => (
				<div key={index}>
					<Link to={item.path} onClick={onClickCallback}>
						<h3>{item.name}</h3>
					</Link>
					<ul>
						{item.subitems?.map(
							(subitems: INavbarItem, subindex: number) =>
								subitems.displayMobile && (
									<li key={subindex}>
										<Link to={subitems.path} onClick={onClickCallback}>
											{subitems.name}
										</Link>
									</li>
								)
						)}
					</ul>
				</div>
			))}
		</React.Fragment>
	);
}

export default NavbarItemsMobile;
