import React from "react";
import { INavbarItem } from "../../models/interfaces/INavbarItem";
import "./Navbar-Dropdown.css";
import NavbarItems from "./Navbar-Items";

function NavbarDropdown({
	item,
	dropdown,
	depthLevel
}: {
	item: INavbarItem[];
	dropdown: boolean;
	depthLevel: number;
}) {
	depthLevel++;
	const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

	return (
		<div className="navbar-dropdown">
			<ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
				{item.map((subitem: INavbarItem, index: number) => (
					<NavbarItems item={subitem} key={index} depthLevel={depthLevel} />
				))}
			</ul>
		</div>
	);
}

export default NavbarDropdown;
