import React from "react";
import css from "./ButtonIcon.module.css";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
	icon: JSX.Element;
}

function ButtonIcon({ icon, ...rest }: Props) {
	return (
		<button className={css.button} {...rest}>
			{icon}
		</button>
	);
}

export default ButtonIcon;
