import React from "react";
import { BiSticker } from "react-icons/bi";
import { BsEmojiHeartEyes, BsFlag, BsPostcardHeart } from "react-icons/bs";
import { FaAppleAlt, FaTshirt } from "react-icons/fa";
import { GiCoffeeCup, GiDesk, GiForkKnifeSpoon, GiSofa } from "react-icons/gi";
import { MdCoffeeMaker, MdOutlineMicrowave } from "react-icons/md";
import { RiMentalHealthFill } from "react-icons/ri";
import { TbFridge } from "react-icons/tb";
import { TileCardData } from "../interfaces/ITileCardData";

export const ROOM_ITEMS: TileCardData[] = [
	{
		icon: <GiSofa />,
		text: "Sofas"
	},
	{
		icon: <GiDesk />,
		text: "Petit espace de travail"
	},
	{
		icon: <TbFridge />,
		text: "Mini-réfrigérateur"
	},
	{
		icon: <MdOutlineMicrowave />,
		text: "Micro-onde"
	},
	{
		icon: <MdCoffeeMaker />,
		text: "Mini-cafetière"
	},
	{
		icon: <GiForkKnifeSpoon />,
		text: "Tasses et assiettes"
	},
	{
		icon: <GiCoffeeCup />,
		text: "Café à 1$"
	},
	{
		icon: <FaAppleAlt />,
		text: "Collations gratuites"
	},
	{
		icon: <RiMentalHealthFill />,
		text: "Condoms, tampons et serviettes sanitaires gratuites"
	}
];

export const merchItems: TileCardData[] = [
	{
		icon: <FaTshirt />,
		text: "Chandails"
	},
	{
		icon: <BsEmojiHeartEyes />,
		text: "Macarons"
	},
	{
		icon: <BiSticker />,
		text: "Autocollants"
	},
	{
		icon: <BsPostcardHeart />,
		text: "Cartes d'affaire"
	},
	{
		icon: <BsFlag />,
		text: "Drapeaux"
	}
];
