export enum EColor {
	RED,
	ORANGE,
	YELLOW,
	GREEN,
	BLUE,
	PURPLE
}

export const COLORS_MAP = new Map<EColor, string>([
	[EColor.RED, "#E30613"],
	[EColor.ORANGE, "#EA5B0C"],
	[EColor.YELLOW, "#FFDE00"],
	[EColor.GREEN, "#00983A"],
	[EColor.BLUE, "#27348B"],
	[EColor.PURPLE, "#83368C"]
]);
