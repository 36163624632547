import React from "react";
import css from "./RainbowBanner.module.css";

function RainbowBanner() {
	return (
		<div className={css.container}>
			<div className={css.bgRed}>
				<br />
			</div>
			<div className={css.bgOrange}>
				<br />
			</div>
			<div className={css.bgYellow}>
				<br />
			</div>
			<div className={css.bgGreen}>
				<br />
			</div>
			<div className={css.bgBlue}>
				<br />
			</div>
			<div className={css.bgPurple}>
				<br />
			</div>
		</div>
	);
}

export default RainbowBanner;
