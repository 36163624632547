import { INavbarItem } from "../interfaces/INavbarItem";

export const NAVBAR_ITEMS: INavbarItem[] = [
	{
		name: "Accueil",
		path: "/"
	},
	{
		name: "À propos",
		path: "/a-propos",
		subitems: [
			{
				name: "Le comité",
				path: "/a-propos#le-comite",
				displayMobile: false
			},
			{
				name: "Exécutif",
				path: "/a-propos#executif",
				// subitems: [
				// 	{ name: "2023-2024", path: "" },
				// 	{ name: "2022-2023", path: "" },
				// 	{ name: "2021-2022", path: "" },
				// 	{ name: "Anciens membres", path: "" }
				// ],
				displayMobile: false
			}
		]
	},
	{
		name: "Événements",
		path: "/calendrier",
		subitems: [
			{
				name: "Calendrier 2023-2024",
				path: "/calendrier",
				displayMobile: true
			},
			{
				name: "Semaine de la fierté",
				path: "/semaine-fierte",
				displayMobile: true
			},
			{
				name: "Soirée réseautage",
				path: "/soiree-reseautage",
				displayMobile: true
			}
		]
	},
	{
		name: "Ressources",
		path: "/local",
		subitems: [
			{
				name: "Local B-313",
				path: "/local",
				subitems: [
					{
						name: "Horaire de permanence",
						path: "/local#horaire"
					},
					{
						name: "Livres",
						path: "/local#livres"
					},
					{
						name: "Articles d'affirmations de genre",
						path: "/local#articles-affirmation"
					},
					{
						name: "Articles promotionnels",
						path: "/local#merch"
					}
				],
				displayMobile: true
			},
			{
				name: "Toilettes non genrées",
				path: "/toilettes",
				displayMobile: true
			},
			{
				name: "Changement de prénom",
				path: "/changement-prenom",
				displayMobile: true
			},
			{
				name: "Services et organismes",
				path: "/services",
				displayMobile: true
			}
		]
	},
	{
		name: "Contact",
		path: "/contact"
	}
];
